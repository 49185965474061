import Page from '#/components/shared/ui/Page';
import useLocales from '#/lib/hooks/useLocales';
import Hero from '#/components/pages/About/hero';
import Section from '#/components/pages/About/section';
import Feature from '#/components/pages/About/feature-section';

const CHAIRMAN = 'Alain Veuve';

export default function AboutUs() {
  const { translate } = useLocales();

  return (
    <Page
      title="Ueber Uns"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
      }}
    >
      <Hero translate={translate as any} />
      <Section
        imageUrl="/assets/images/main-landings/sections/about_us_1.webp"
        title={String(translate('aboutUs.section1.title'))}
        subtitle={String(translate('aboutUs.section1.subtitle'))}
        imageFirst
      />
      <Section
        imageUrl="/assets/images/main-landings/sections/about_us_2.webp"
        title={String(
          translate('aboutUs.section2.title', { chairMan: CHAIRMAN })
        )}
        subtitle={String(
          translate('aboutUs.section2.subtitle', {
            chairMan: CHAIRMAN,
          })
        )}
        imageFirst={false}
      />
      <Feature
        label={String(translate('aboutUs.ourGoal.label'))}
        title={String(translate('aboutUs.ourGoal.title'))}
        subtitle={String(translate('aboutUs.ourGoal.subtitle'))}
      />
      <Section
        imageUrl="/assets/images/betascale_logo.webp"
        title={String(translate('aboutUs.section3.title'))}
        subtitle={String(translate('aboutUs.section3.subtitle'))}
        imageFirst={false}
        imageSX={{
          height: '100%',
        }}
        sx={{
          alignItems: 'center',
        }}
      />
      <Feature
        label={String(translate('aboutUs.partners.label'))}
        title={String(translate('aboutUs.partners.title'))}
        subtitle={String(translate('aboutUs.partners.subtitle'))}
        sx={{
          position: 'relative',
          backgroundImage:
            'url(/assets/images/backgrounds/partners_section.webp)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          color: 'white',
          paddingY: 10,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#5678DF',
            opacity: 0.8,
            zIndex: 1,
          },
        }}
      />
    </Page>
  );
}
